import React, { useState } from 'react';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import * as Sentry from '@sentry/gatsby';
// services
import { isSSR } from '../services/global';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';
import RegisterForm from '../components/register-form';
// services
import { isEmpty } from '../services/global';
import { getUtmParamsMap } from '../services/tracking/tracking';
import { GraphQLError } from '../interfaces/graphql';
import { GRAPH_QL_ERROR_TYPES } from '../constants';
// hooks
import { useRegisterText } from '@hooks/directusHooks/useRegisterText';
import { useRegisterUser } from '@hooks/useRegisterUser';
// styles
import styles from './register.module.scss';

const Register: React.FC = () => {
  const location = useLocation();
  const previousPath = location?.state?.path;
  const [errorMessages, setErrorMessages] = useState<object>({});

  const directusAccountRegister = useRegisterText();

  const successRedirect = !isSSR && new URLSearchParams(window.location.search).get('successRedirect');

  // state
  const { errors } = useForm();

  //hooks
  const { handleRegisterUser, loading } = useRegisterUser();

  const onComplete = () => {
    let redirectPath = previousPath || '/account/dashboard';
    if (successRedirect === 'referral') redirectPath = '/account/referral';
    navigate(redirectPath);
  };

  const onError = (error: GraphQLError) => {
    const findError = error.graphQLErrors.find((x: GraphQLError) => GRAPH_QL_ERROR_TYPES[x.errorType]);

    if (findError?.errorType === GRAPH_QL_ERROR_TYPES.EmailExists) {
      Sentry.captureMessage('An account with this email already exists', Sentry.Severity.Log);
      setErrorMessages({
        email: 'An account with this email already exists',
      });
    }
  };

  const onSubmit = async (formData: any) => {
    setErrorMessages({});
    if (!isEmpty(errors)) return;

    try {
      await handleRegisterUser(formData.firstName, formData.email);
      onComplete();
    } catch (e) {
      onError(e);
    }
  };

  return (
    <div>
      <SEO title={directusAccountRegister.page_title} description={directusAccountRegister.page_description} />
      <section className={styles.section}>
        <div className={styles.container}>
          <h2>{directusAccountRegister.title}</h2>
          <hr />
          <div className={styles.row}>
            <div className={styles.col}>
              <RegisterForm onSubmit={onSubmit} serverErrorMessages={errorMessages} loading={loading} />
              <h4>{directusAccountRegister.already_has_account}</h4>
              <Link
                to={successRedirect ? `/account/signin?successRedirect=${successRedirect}` : '/account/signin'}
                className={styles.buttonGhost}
              >
                {directusAccountRegister.login_button_label}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const RegisterInLayout: React.FC = () => {
  return (
    <Layout showFooter={false}>
      <Register />
    </Layout>
  );
};

export default RegisterInLayout;
