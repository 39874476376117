import { graphql, useStaticQuery } from 'gatsby';

export const useRegisterText = () => {
  const { directusAccountRegister } = useStaticQuery(
    graphql`
      query Register {
        directusAccountRegister {
          page_title
          page_description
          title
          first_name_label
          email_label
          submit_button_label
          already_has_account
          login_button_label
        }
      }
    `
  );
  return directusAccountRegister;
};
